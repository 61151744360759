import { myTheme } from "../../MyTheme";

const {
    color: { facebookBlue, defaultBrand },
} = myTheme;

const btnColors = {
    normal: defaultBrand,
    facebook: facebookBlue,
};

export { btnColors };
