import styled from "styled-components";

const Styles = {
    Wrapper: styled.div`
        width: calc(100% - 24px);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    `,
};

export default Styles;
