import styled from "styled-components";

const Styles = {
    Wrapper: styled.div`
        width: 1200px;
        display: flex;
        flex-direction: column;
        align-items: center;
    `,
};

export default Styles;
