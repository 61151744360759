import React from "react";
import Layout from "../domain/Layout";
import PageContent from "../domain/PageContent";
import { graphql } from "gatsby";

export const query = graphql`
    query ($skip: Int!, $limit: Int!) {
        allMarkdownRemark(
            sort: { fields: frontmatter___id, order: ASC }
            filter: { fileAbsolutePath: { regex: "/(1-100)/" } }
            skip: $skip
            limit: $limit
        ) {
            edges {
                node {
                    frontmatter {
                        images {
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    layout: CONSTRAINED
                                )
                            }
                        }
                        alts
                        category
                        date
                        id
                        keywords
                        slug
                        source
                        subTitle
                        text
                        title
                        layout
                    }
                }
            }
        }
    }
`;

const CardsPage = ({
    data: {
        allMarkdownRemark: { edges },
    },
}) => {
    console.log(edges);
    return (
        <Layout>
            <PageContent pageData={edges} />
        </Layout>
    );
};

export default CardsPage;
