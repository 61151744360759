import styled, { css } from "styled-components";
import { myTheme } from "../../MyTheme";
import { IBtn } from "./types";

import { btnColors } from "./helpers";

const {
    color: { textDefault },
    fontSize: { normal },
    fontWeight: { bold },
} = myTheme;

const Styles = {
    Btn: styled.button<IBtn>`
        ${({ buttonType }) =>
            css`
                background-color: ${btnColors[buttonType]};
            `}
        color: ${textDefault};
        border-radius: 3px;
        padding: 12px 17px 12px 17px;
        font-size: ${normal};
        font-weight: ${bold};
        outline: none;
        border: none;
        transition: 0.3s;
        &:hover {
            cursor: pointer;
        }
    `,
};

export default Styles;
