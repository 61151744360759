import React from "react";
import Styles from "./styles";
import ContentCard from "../ContentCard";
import dummyCardsData from "../../DummyData/cardsData";
import { IPageContent } from "./type";
import { graphql } from "gatsby";

export const query = graphql`
    query ($skip: Int!, $limit: Int!) {
        allMarkdownRemark(
            sort: { fields: frontmatter___id, order: ASC }
            filter: { fileAbsolutePath: { regex: "/(1-100)/" } }
            skip: $skip
            limit: $limit
        ) {
            edges {
                node {
                    frontmatter {
                        images {
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    layout: CONSTRAINED
                                )
                            }
                        }
                        alts
                        category
                        date
                        id
                        keywords
                        slug
                        source
                        subTitle
                        text
                        title
                        layout
                    }
                }
            }
        }
    }
`;

const { Wrapper } = Styles;

const PageContent = (props: IPageContent) => {
    console.log(props);
    return (
        <Wrapper>
            {props.pageData.map(
                ({
                    node: {
                        frontmatter: {
                            id,
                            title,
                            subTitle,
                            text,
                            images,
                            category,
                            layout,
                            source,
                            date,
                        },
                    },
                }) => (
                    <ContentCard
                        key={id}
                        title={title}
                        subtitle={subTitle}
                        text={text}
                        pictures={images}
                        category={category}
                        layout={layout}
                        source={source}
                        date={date}
                    />
                )
            )}
        </Wrapper>
    );
};

export default PageContent;
