import React, { PropsWithChildren } from "react";
import Styles from "./styles";
import { IButton } from "./types";

const { Btn } = Styles;

const Button = ({ children, buttonType }: PropsWithChildren<IButton>) => {
    return <Btn buttonType={buttonType}>{children}</Btn>;
};

export default Button;
